
// lib
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

// app
import { generateUniqueId } from '@/app/shared/utils/unique'
import Proscons, { IProscons } from '@/app/article/editor/proscons/shared/model'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import isEqual from 'lodash-es/isEqual'

@Component({
  components: { UiFormGroup }
})
export default class AppArticleProscons extends Vue {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  pros: Proscons[] = []
  cons: Proscons[] = []
  prosValue = ''
  consValue = ''

  get computedProscons (): [Proscons[], Proscons[]] {
    return [this.pros, this.cons]
  }

  get valueProp (): { pros: Proscons[], cons: Proscons[] } {
    const pros = (this.value.attrs.pros as IProscons[] | undefined) ?? []
    const cons = (this.value.attrs.cons as IProscons[] | undefined) ?? []

    return {
      pros: pros.map(v => new Proscons(v)),
      cons: cons.map(v => new Proscons(v))
    }
  }

  handleAddProscons (name: string, value: string): void {
    const resultArray = value.split(/\r?\n/g)
    resultArray.forEach(text => {
      if (text) {
        this.$data[name].push({
          $uuid: generateUniqueId(),
          text
        })
      }
    })
    this.$data[`${name}Value`] = ''
  }

  handleDeleteProscons (name: string, $uuid: string): void {
    this.$data[name] = this.$data[name].filter((v: Proscons) => v.$uuid !== $uuid)
  }

  handleUpProscons (arrayName: string, $uuid: string): void {
    const foundIndex = this.$data[arrayName].findIndex((v: Proscons) => v.$uuid === $uuid)

    if (foundIndex === 0) {
      return
    }

    const prev = this.$data[arrayName][foundIndex - 1]
    this.$set(this.$data[arrayName], foundIndex - 1, this.$data[arrayName][foundIndex])
    this.$set(this.$data[arrayName], foundIndex, prev)
  }

  handleDownProscons (arrayName: string, $uuid: string): void {
    const foundIndex = this.$data[arrayName].findIndex((v: Proscons) => v.$uuid === $uuid)

    if ((this.$data[arrayName].length - 1) === foundIndex) {
      return
    }

    const next = this.$data[arrayName][foundIndex + 1]
    this.$set(this.$data[arrayName], foundIndex + 1, this.$data[arrayName][foundIndex])
    this.$set(this.$data[arrayName], foundIndex, next)
  }

  @Watch('valueProp', { immediate: true, deep: true })
  changeData (value: { pros: Proscons[], cons: Proscons[] }, valueOld: { pros: Proscons[], cons: Proscons[] }): void {
    if (!isEqual(value, valueOld)) {
      this.pros = value.pros
      this.cons = value.cons
    }
  }

  @Watch('computedProscons', { deep: true })
  emitArticleProscons (value: [Proscons[], Proscons[]]): void {
    this.$emit('input', this.$emit('input', new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        pros: value[0].map(v => new Proscons(v)),
        cons: value[1].map(v => new Proscons(v))
      }
    })))
  }
}
