// app
import Model from '@/app/shared/models/Model'

export interface IProscons {
  text: string
}

export default class Proscons extends Model<IProscons> implements IProscons {
  text = ''

  constructor (props?: IProscons) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }
}
